import { Newsletter } from './newsletter.model';
import { enuDSGVOVertragTyp, enuMitgliedsPruefung, enuRegistrierungAls } from './../globals/enumerationen';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DxFormComponent, DxValidationGroupModule, DxValidationSummaryModule} from 'devextreme-angular';
import { AppConfig } from '../app.config';
import notify from 'devextreme/ui/notify';
import validationEngine from 'devextreme/ui/validation_engine';
import { alert, custom } from 'devextreme/ui/dialog';
import CodiceFiscale from 'codice-fiscale-js';
import * as funktionen from '../globals/functions';
import moment from 'moment';

import Validator from 'devextreme/ui/validator';
import { NewsletterService } from './newsletter.service';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-newsletter',
  providers: [ NewsletterService ],
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent implements OnInit {
  @ViewChild('formNewsletter', { static: false }) formNewsletter: DxFormComponent;

  anrede: any[];
  interessen: string[];
  buttonSubmitOptions: any;

  daten: any;
  newsletterButtonOptions: any;
  buttonInfoDatenschutz: any;

  showRegisterMessage = false;

  constructor(
    public service: NewsletterService,
    public http: HttpClient,
    private authService: AuthService,
    public translate: TranslateService
  ) {
    this.anrede = service.getAnrede();
    this.interessen = service.getInteressen();
    this.daten = new Newsletter();

    this.okLogin = this.okLogin.bind(this);

  }

  ngOnInit(): void {

    this.buttonInfoDatenschutz = {
      icon: 'info',
      text: 'Datenschutzbestimmungen',
      hint: 'Datenschutzbestimmungen öffnen',
      type: 'success',
      stylingMode: 'text',
      useSubmitBehavior: false,
       onClick: (e) => {
        funktionen.openDSVG('_blank', this.authService, enuDSGVOVertragTyp.Website);
      }
    };


  }

  public okLogin(e){
    console.log('login');
    // this.loadingVisible = true;
    const groupobject = e.validationGroup.group;
    const result = validationEngine.validateGroup(groupobject);
    if (result.isValid) {

      this.authService.putUrl('/portal/addnewsletter', { emailadresse: this.daten['email'], anrede: this.daten['anrede'], vorname: this.daten['vorname'], nachname: this.daten['nachname']}).then((res) => {
        // notify('Newsletterbenutzer angelegt', 'success', 1000);
        this.showRegisterMessage = true;
      })
      .catch((error) => {
        console.log('onSignin 2', error);
        notify('Fehler', 'error', 1000);
      });
    }


    // this.loadingVisible = false;

  }

  datenschutzComparison() {
    return true;
  }

  openDSVG(){
    funktionen.openDSVG('_blank', this.authService, enuDSGVOVertragTyp.Website);
  }

  submitData() {
    const result = validationEngine.validateGroup('newsletterValidator');
    if (result) {
      console.log('Daten validiert!');
    }
  }


}
