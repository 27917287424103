import { Register } from './register.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DxFormComponent} from 'devextreme-angular';
import { RegisterService } from './register.service';
import validationEngine from 'devextreme/ui/validation_engine';
import { alert, custom } from 'devextreme/ui/dialog';
import moment from 'moment';
import DataSource from 'devextreme/data/data_source';
import { Kontakt } from './kontakt.model';
import * as funktionen from '../globals/functions';
import Editor from 'devextreme/ui/editor/editor';
import { ActivatedRoute, Router } from '@angular/router';
import { forEach } from 'typescript-collections/dist/lib/arrays';
import { GlobaleDatenService } from '../services/globaledaten.serice';
import { TranslateService } from '@ngx-translate/core';
import { enuKunde } from '../app-config.model';

@Component({
  selector: 'app-register2',
  providers: [ RegisterService ],
  templateUrl: './register2.component.html',
  styleUrls: ['./register.component.scss']
})
export class Register2Component implements OnInit {
  public loadingVisible = false;
  public showRegisterMessage = false;
  public showErrorMessage = false;
  public errorMessage = '';

  window = window; 
  enuKunde =enuKunde;
  constructor(
    public Service: RegisterService,
    private router: Router,
    private route: ActivatedRoute,
    private gdService: GlobaleDatenService,
    public translate: TranslateService
  ) {
    this.loadingVisible = true;


  }


  ngOnInit(): void {
    this.loadingVisible = true;
    this.Service.authservice.putUrl('/portal/addbenutzer', {id: this.route.snapshot.params.id, locale :localStorage.getItem('locale')}).then( (data) => {
      if (funktionen.isEmptyString(data['Fehlermeldung'])) {
        this.Service.authservice.register2(this.route.snapshot.params.id).then((res) => {
          this.loadingVisible = false;
          this.showRegisterMessage = true;
          this.showErrorMessage = false;
        }).catch((error) => {
          this.showErrorMessage = true;
          this.showRegisterMessage = false;
          this.errorMessage += funktionen.fixUmlaute(error.statusText) + '\n';
        });
      } else if (data) {
        this.showErrorMessage = true;
        this.showRegisterMessage = false;
        this.errorMessage += data['Fehlermeldung'] + '\n';
      }
    }).catch((error) => {
      this.showErrorMessage = true;
      this.showRegisterMessage = false;
      if (error.statusText === 'ServerGesperrt') {
        this.errorMessage += this.translate.instant('SERVERGESPERRT') + '\n';
        this.errorMessage += this.translate.instant('VERSUCHSPAETER') + '\n';
      } else if (error.statusText !== 'Unknown Error') {
        this.errorMessage += funktionen.fixUmlaute(error.statusText) + '\n';
      }else {
        this.errorMessage += this.translate.instant('REGISTER2.FEHLERREG') + '\n';
      }
    });
  }

}
